.promptForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promptCard {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.promptText {
  font-size: 1.2em;
  color: #333;
  margin: 0;
}

.responseInput {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  margin-bottom: 20px;
}

.submitButton {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.submitButton:hover {
  background-color: #0056b3;
}

.toastButton {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.9em;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.2s ease-in-out;
}

.toastButton:hover {
  background-color: #218838;
}