.entriesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.entryCard {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s ease-in-out;
  position: relative;
}

.entryCard:hover {
  transform: translateY(-5px);
}

.entryDate {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}

.entryPrompt {
  font-weight: bold;
  margin-bottom: 10px;
}

.entryResponse {
  white-space: pre-wrap;
  margin-bottom: 20px;
}

.likeButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.likeButton:hover {
  transform: scale(1.1);
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
}

.tab:hover {
  background-color: #e0e0e0;
}

.activeTab {
  background-color: #007bff;
  color: white;
}