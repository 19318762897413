.homeContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.title {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 40px;
}